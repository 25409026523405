import React, { useState } from 'react';

function App() {
  const [codigoPostal, setCodigoPostal] = useState('');
  const [costoEnvio, setCostoEnvio] = useState(null);
  const [error, setError] = useState(null);

  const tarifasEnvioPorCP = {
    "28931": 15,
    "28932": 12,
    "28933": 15,
    "28934": 12,
    "28935": 15,
    "28936": 15,
    "28922": 15,
    "28230": 20,
    "28231": 20,
    "28232": 20,
    "28290": 20,
    "28220": 17,
    "28221": 17,
    "28222": 17,
    "28935": 15,
    "28939": 20,
    "28600": 30,
    "28670": 20,
    "28025": 7,
    "28914": 8,
    "28911": 8,
    "28052": 20,
    "28030": 12,
    "28022": 15,
    "28820": 20,
    "28022": 20,
    "28022": 20,
    "28521": 25,
    "28522": 25,
    "28523": 25,
    "28500": 30,
    "28840": 25,
    "28850": 40,
    "28801": 50,
    "28802": 50,
    "28803": 50,
    "28042": 25,
    "28033": 15,
    "28918": 12,
    "28922": 12,
    "28942": 15,
    "28943": 15,
    "28944": 15,
    "28945": 15,
    "28946": 15,
    "28109": 20,
    "28970": 20,
    "28971": 25,
    "28026": 5,
    "28041": 7,
    "28041": 7,
    "28021": 8,
    "28026": 8,
    "28041": 8,
    "28021": 10,
    "28021": 10,
    "28903": 8,
    "28905": 10,
    "28906": 12,
    "28905": 12,
    "28907": 15,
    "28980": 20,
    "28981": 20,
    "28320": 20,
    "28341": 20,
    "28340": 25,
    "28341": 25,
    "28991": 25,
    "28031": 12,
    "28032": 12,
    "28051": 15,
    "28051": 15,
    "28031": 15,
    "28031": 15,
    "28032": 15,
    "28005": 7,
    "28045": 7,
    "28045": 7,
    "28012": 7,
    "28045": 7,
    "28007": 10,
    "28012": 10,
    "28009": 12,
    "28009": 12,
    "28009": 12,
    "28007": 12,
    "28001": 12,
    "28006": 12,
    "28028": 12,
    "28027": 12,
    "28001": 12,
    "28014": 12,
    "28010": 12,
    "28003": 12,
    "28015": 12,
    "28039": 12,
    "28020": 12,
    "28020": 12,
    "28008": 12,
    "28050": 20,
    "28050": 20,
    "28034": 15,
    "28029": 15,
    "28034": 15,
    "28034": 15,
    "28023": 15,
    "28046": 15,
    "28100": 25,
    "28109": 25,
    "28700": 25,
    "28701": 25,
    "28109": 25,
    "28760": 30,
    "28761": 30,
    "28703": 30,
    "28860": 30,
    "28803": 30,
    "28691": 35,
    "28330": 35,
    "28004": 12,
    "28013": 12,
    "28054": 5,
    "28025": 5,
    "28019": 5,
    "28019": 5,
    "28011": 5,
    "28044": 5,
    "28011": 5,
    "28011": 5,
    "28011": 5,
    "28011": 5,
    "28023": 4,
    "28044": 4,
    "28223": 5,
    "28023": 15,
    "28660": 15,
    "28669": 15,
    "28223": 12,
    "28224": 12,
    "28230": 12,
    "28008": 10,
    "28002": 15,
    "28003": 15,
    "28046": 15,
    "28923": 15,
    "28921": 15,
    "28924": 12,
    "28925": 12

    
  };

  const calcularCostoEnvio = (codigoPostal) => {
    const tarifaPorCP = tarifasEnvioPorCP[codigoPostal];
    if (tarifaPorCP) {
      setCostoEnvio(tarifaPorCP);
      setError(null);
    } else {
      setError('El código postal ingresado no es válido');
      setCostoEnvio(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (codigoPostal) {
      // Aquí podrías hacer más validaciones si lo consideras necesario
      calcularCostoEnvio(codigoPostal);
    } else {
      setError('Por favor, ingresa un código postal');
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>ChopSuey</h1>
      <h2>Calculadora de Costo de Envío</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" value={codigoPostal} onChange={(e) => setCodigoPostal(e.target.value)} placeholder="Código Postal" />
        <button type="submit">Calcular Costo de Envío</button>
      </form>
      {costoEnvio !== null && <p>El costo de envío es de {costoEnvio} euros.</p>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default App;